<template>
  <b-modal ref="hold-on-modal"
           id="special-modal"
           v-model="modalState"
           header-class="modal-header_special"
           footer-class="modal-footer_special"
           dialog-class="modal-dialog_special"
           content-class="modal-content_special"
           body-class="modal-body_special"
           centered>
    <template #modal-header>
      <div class="w-100 d-flex justify-content-center">
        <img src="../../assets/special_offer_title.png" alt="" class="modal-header__img">
      </div>
    </template>
    <p class="special-p text-white">
      We want to help improve your math so we are offering you a special discount.
    </p>
    <template #modal-footer>
      <div class="d-grid gap-2 col-12">
        <button type="button" class="btn btn-light btn-lg btn-special" @click="continueBtnClick">
          <b>
            Get my discount
          </b>
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'SpecialOfferModal',
  props: [
    'isModalShow',
  ],
  computed: {
    modalState: {
      get() {
        return this.isModalShow;
      },
      set(value) {
        this.$emit('onstatechange', value);
      },
    },
  },
  methods: {
    continueBtnClick() {
      this.$store.dispatch('clickHouseEvent', {
        event_type: 'landing_specialoffer_popup_click',
      });
      this.$router.push('/special-offer');
    },
  },
};
</script>

<style lang="scss">
.modal {
  &-dialog_special {
    max-width: 470px;
  }
  &-header {
    &_special {
      max-height: 230px;
      padding: 0;
      margin-bottom: 55px;
    }

    &__img {
      max-width: 514px;
    }
  }
  &-content_special {
    background-color: #5E5CE6;
  }
  &-body_special {
    padding-top: 20px;
    padding-bottom: 0;
    text-align: center;
  }
  &-footer_special {
    border: 0;
    padding: 40px;
  }
}
.btn-special {
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 8px;
}
.special-p {
  max-width: 306px;
  margin: 0 auto;
}
</style>
