<template>
  <div class="success-container container d-flex flex-column align-items-center position-relative">
    <div v-if="loaderShow"
         class="d-flex align-items-center
           justify-content-center
           position-absolute
           w-100 h-100
           bg-white" style="z-index: 1">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <img class="success-image" src="../assets/400p.png" alt="">
    <h1 class="success-title">
      {{ message }}
    </h1>
    <template
      v-if="($store.state.credentials.deeplink &&
       $store.state.credentials.uuid) || message === 'success'"
    >
      <p class="text-center" v-if="$store.state.credentials.email">
        <b>Login:</b>
        <span class="text-primary">
        {{ $store.state.credentials.email }}
      </span>
      </p>
      <p class="text-center" v-if="$store.state.credentials.password">
        <b>Pass:</b>
        <span class="text-primary">
        {{ $store.state.credentials.password }}
      </span>
      </p>
      <p class="text-center"
         v-if="!$store.state.credentials.deeplink && thatGetCookie('appLink')">
        If you didn't find our email with instructions, please check spam and other email folders
        or contact us via <a href="mailto:support@mathmaster.me">support@mathmaster.me</a>
      </p>
      <button
        type="button"
        v-if="$store.state.credentials.deeplink || thatGetCookie('appLink')"
        @click="continueBtnClick"
        class="btn btn-primary site-btn site-btn_primary">
        Continue
      </button>
    </template>
    <template v-else>
      <p class="text-center">
        Unfortunately, the payment has been declined by your bank. Try the following steps:
      </p>
      <ul>
        <li>
          Check your card balance
        </li>
        <li>
          Check your card limits
        </li>
        <li>
          Contact your bank
        </li>
      </ul>
      <button type="button" class="btn btn-outline-warning" @click="tryAgainClick">
        Try again
      </button>
    </template>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import * as Processing from 'processing';
// eslint-disable-next-line import/no-cycle
import { getCookie, setCookie } from '@/main';

export default {
  name: 'Success',
  data() {
    return {
      loaderShow: true,
      message: '',
    };
  },
  methods: {
    thatGetCookie(name) {
      return getCookie(name);
    },
    transactionHandler() {
      this.$store.dispatch('checkTransactionStatus',
        window.localStorage.getItem('order_uuid') || this.$route.query.order_uuid)
        .then((resp) => {
          if (resp.status === 'success') {
            window.fbq('track', 'Purchase');
            window.gtag('event', 'conversion', { // 17_12_21...
              send_to: 'AW-393469566/HM8WCOyV0YoDEP68z7sB',
              transaction_id: '',
            });
            this.getCredentials()
              .then((credResp) => {
                this.$store.commit('setCredentials', credResp.data);
                setCookie('applicationLink',
                  `https://app.expertchat.me/?auth=${this.$store.state.credentials.uuid}`);
                this.$store.dispatch('clickHouseEvent', {
                  event_type: 'landing_success_page',
                });
                this.loaderShow = false;
              });
          } else {
            this.message = resp.status;
            this.$store.dispatch('clickHouseEvent', {
              event_type: 'landing_fail_payment_page',
            });
            this.loaderShow = false;
          }
        });
    },
    getCredentials() {
      return Processing.Processing.getCredentials();
    },
    tryAgainClick() {
      this.$store.dispatch('clickHouseEvent', {
        event_type: 'landing_fail_payment_page_retry',
      });
      this.$router.push({
        path: '/plans',
        query: { cause: 'refuse' },
      });
    },
    continueBtnClick() {
      this.$store.dispatch('clickHouseEvent', {
        event_type: 'landing_success_page_click',
      })
        .then(() => {
          window.open(this.thatGetCookie('appLink')
            || `${this.$store.state.credentials.deeplink}&auth=${this.$store.state.credentials.uuid}`);
        });
    },
  },
  created() {
    if (window.localStorage.getItem('appLink') && !this.$route.query.order_uuid) { // catch page reload
      this.loaderShow = false;
    } else { // solid/maxpay/paypal transaction handler
      window.localStorage.removeItem('appLink');
      setTimeout(this.transactionHandler, 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/bootstrap';

.success {
  &-image {
    max-width: 450px;
    width: 100%;
  }

  &-title {
    font-size: 2rem;
  }

  &-button {
    font-size: 1rem;
  }
}

</style>
